/* Provide sufficient contrast against white background */
html {
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 40px;
}

.base-loader{
  position: fixed !important;
  top: 0 !important;
  bottom: 0 !important;
  right: 0 !important;
  left: 0 !important;
  background: white !important;
}

body {
  overflow: auto;
}

#root{
  min-height: 100vh;
  
  > div{
    height: 100%;
  }
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.child-text-center > h1, .child-text-center > div, .text-center{
  text-align: center;
}

.flag-icon {
  height: 36px;
}

h1{
  padding-bottom: 50px;
  font-size: 30px;
}

.order-message{
  padding-top: 20px
}

.body-container{
  margin: 0 auto;
  padding-top: 30px;
  padding-right: 0;
  padding-left: 0;
  overflow:hidden;
  width: 400px;
}
html { 
  zoom: .8; 
}

header {
  padding: 7px 25px;
  background-color: #1E90FF;
  color: white;
  min-height: 25px;
  display: inline-block;
  background-color: rgb(70, 114, 196);
  width: 100%;
  min-height: 51px;
  min-width: 400px;
  > div {
    display: inline;
  }
  a:hover{
    color: white;
  }
  .lang-list {
    float: right;
    padding-top: 5px;

    a:not(:last-of-type) {
      padding-right: 10px;
    }
  }
}




// .flag-icon-sv:after {
//   content: url(../src/assets/images/flags/sv.png);
// }
// .flag-icon-en:after {
//   content: url(../src/assets/images/flags/eng.png);
// }
// .flag-icon-da:after {
//   content: url(../src/assets/images/flags/da.png);
// }
// .flag-icon-lt:after {
//   content: url(../src/assets/images/flags/lt.png);
// }

// .logo-icon:after {
//   content: url(../src/assets/images/logoDefault.png);
// }

.navbar-brand {
  position: relative;
  color:inherit;
  font-weight: bold;
  font-size: 20px;
  .logo-icon {
    height: 36px;
  }
}

.order-info{
  font-size: 22px;
}

.button-info{
  padding-bottom: 10px;
}

.main-button{
  border-radius: 10px;
  border: none;
  font-size: 20px;
  font-weight: bold;
  width: 290px;
  min-height: 60px;
  display: block;
  margin: 0 auto 30px auto;
  padding: 15px 0;
}

.error-message,
.success-message,
.warning-message,
.order-message{
  font-size: 23px;
}

.error-message{
  color: red;
}

.success-message{
  color:green;
}

.warning-message{
  color: orange;
}


#barcodeScanner{
  .drawingBuffer {
    position: absolute;
    top: 0;
    left: 0;
    display:none;
  }

  video{
    width: 100%;
  }
}

.timeout-clock{
    width: 86px;
    text-align: center;
    display: block;
    float: right;
    clear: both;
    font-size: 18px;
    height: 45px;
    margin-top: -17px;
    margin-right: 0;
}

.body-row{
  display: inline-block;
  width: 100%;

}

.box-button,
.main-button > div{
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

.boxes-container{
  margin-right: -2.5px;
  margin-left: -2.5px;
  text-align: left;
  .box-container{
      padding-right: 2.5px;
      padding-left: 2.5px;
      padding-bottom: 5px;
      width: 33.3333%;
      display: inline-block;
    .box{
      border: 1px solid;
      position: relative;
      border-radius: 22px;
      height: 130px;
      overflow: hidden;

      .box-loading-indicator{
        background-color: white;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        position: absolute;
        opacity: 0.5;
        display: none;
        text-align: center;
        svg{
          height: 100%;
        }
      }
      
      .box-no{
        font-weight: bold;
        font-size: 37px;
        padding-top: 16px;
        text-align: center;
      }
      .box-button{
        border: 1px solid;
        border-radius: 30px;
        position: absolute;
        width: 100%;
        bottom: 0px;
        text-align: left;
        padding-left: 9px;
        height: 40px;
        font-size: 20px;
        padding-top: 3px;
        cursor: pointer;
        .lock-icon-container{
          border: 1px solid;
          height: 40px;
          width: 40px;
          display: inline-block;
          border-radius: 20px;
          position: absolute;
          bottom: -2px;
          left: 0;
          background-color: #FFFFFF;

          .box-lock-icon{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 22px;
            height: auto;
          }
        }
      }
    }
  }
  
}
